<template>
    <div class="layout-page">
        <div class="restaurant-container">
            <div class="your-restaurants">
                {{ $t('restaurants.your_restaurants') }}
            </div>
            <div class="search">
                <input type="text" v-model="search" class="input-search" name="name" />
            </div>
        </div>

        <div class="data-table">
            <v-data-table v-if="items" disable-pagination :headers="headers" fixed-header :search="search" :items="Object.values(items)" sort-by="name" :sort-asc="true" :hide-default-footer="true">
                <template #item.code="item">
                    <div class="ticket" :class="{ error: item.item.errorDates, inactive: item.item.status == 0 }">{{ item.item.code }}</div>
                </template>
                <template #item.name="item">
                    <div class="row" :class="{ error: item.item.errorDates, inactive: item.item.status == 0 }">{{ item.item.name }}</div>
                </template>
                <template #item.fiscal_address="item">
                    <div class="row" :class="{ error: item.item.errorDates, inactive: item.item.status == 0 }">{{ item.item.cif + ' - ' + item.item.fiscal_name + ' - ' + (item.item.alternative_address !== null ? item.item.alternative_address : item.item.fiscal_address) }}</div>
                </template>
                <template #item.dates="item">
                    <div v-if="item.item.errorDates" class="row error">{{ item.item.dates }}</div>
                </template>
                <template #item.edit="item">
                    <div class="actions">
                        <div class="edit-restaurant" @click="editRestaurant(item.item.id)"></div>
                        <div v-if="item.item.status == 0" class="inactive-restaurant" @click="activateRestaurant(item.item.id)">{{ $t('restaurants.activate') }}</div>
                        <div v-else class="active-restaurant"></div>
                    </div>
                </template>
                <template v-slot:no-data>
                    <div class="no-data">
                        {{ $t('bills.table_no_data') }}
                    </div>
                </template>
            </v-data-table>
            <div class="info" v-html="$t('restaurants.table_info')"></div>
        </div>
        <Modal v-if="showModal" type="termsAndConditions" :data="termsAndConditions" @close="closePopup" @confirm="activateAutofactura" />
    </div>
</template>

<script>
import Modal from '@/components/ui/Modal.vue'
export default {
    components: { Modal },
    name: 'Restaurants',
    data() {
        return {
            search: '',
            exportButton: false,
            showModal: false,
            termsAndConditions: null,
            selectedRestaurantId: null
        }
    },
    computed: {
        items() {
            const restaurants = this.$store.getters['restaurant/getRestaurants']()
            const now = this.moment()
            for (const restaurant of Object.values(restaurants)) {
                if (restaurant.start_date && restaurant.end_date) {
                    const startDate = this.moment(restaurant.start_date)
                    const endDate = this.moment(restaurant.end_date)

                    restaurant.errorDates = !this.moment(now).isBetween(startDate, endDate)
                    restaurant.dates = `${this.moment(restaurant.start_date).format('DD/MM/YYYY')} - ${this.moment(restaurant.end_date).format('DD/MM/YYYY')}`
                }
            }

            return restaurants
        },
        headers() {
            return [
                {
                    text: this.$t('restaurants.number_site'),
                    align: 'start',
                    sortable: true,
                    value: 'code',
                    width: 'auto',
                    class: 'header-table'
                },
                {
                    text: this.$t('customers.name'),
                    value: 'name',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('restaurants.fiscal_data'),
                    value: 'fiscal_address',
                    align: 'start',
                    width: '800px',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: '',
                    value: 'dates',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: '',
                    value: 'edit',
                    align: 'start',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                }
            ]
        },
        nRegistros() {
            var letter = this.$t('bills.show_bills')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    methods: {
        load() {
            const self = this
            this.$overlay.show()
            this.$store.dispatch('restaurant/loadRestaurants').then(function () {
                self.$overlay.hide()
            })
        },
        editRestaurant(restaurantId) {
            this.$router.push({ name: 'RestaurantEdit', params: { id: restaurantId } })
        },
        async activateRestaurant(restaurantId) {
            this.$overlay.show()
            try {
                const termsAndConditions = await this.$store.dispatch('restaurant/downloadTermsAndConditions')
                this.termsAndConditions = termsAndConditions
                this.showModal = true
                this.selectedRestaurantId = restaurantId
            } catch (error) {
                console.error(error)
            } finally {
                this.$overlay.hide()
            }
        },
        closePopup() {
            this.showModal = false
            this.selectedRestaurantId = null
            this.termsAndConditions = null
        },
        async activateAutofactura() {
            try {
                this.$overlay.show()
                await this.$store.dispatch(`restaurant/activateAutofactura`, { restaurantId: this.selectedRestaurantId })
            } catch (error) {
                console.error(error)
            } finally {
                this.closePopup()
                this.$overlay.hide()
            }
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss">
.restaurant-container {
    .search {
        .input-search {
            background-image: url('../../public/img/searcher.svg');
            margin-top: unset;
        }
    }
}
.data-table {
    .info {
        .text {
            margin-top: 30px;
            color: #3f3e3e;
            font-family: Speedee-lt;
            display: contents;

            a {
                color: #3f3e3e;
                font-family: Speedee-lt;
                text-decoration: none;
            }
        }
    }

    .row {
        &.inactive {
            color: #d3d3d3;
        }
    }

    .ticket {
        &.error {
            color: $red !important;
            font-weight: bold;
        }
        &.inactive {
            color: #d3d3d3 !important;
        }
    }

    .error {
        color: $red;
        font-weight: bold;
    }

    .actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 8px;
        .edit-restaurant {
            width: 30px;
            height: 30px;
            cursor: pointer;
            background-image: url('../../public/img/pen.svg');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .active-restaurant {
            width: 70%;
        }

        .inactive-restaurant {
            font-size: 12px !important;
            width: 70%;
            font-weight: bold;
            background-color: #ffbc0d;
            padding: 12px 0 4px 8px;
            border-radius: 4px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-height: 40px;
        }
    }
}
</style>
