<template>
    <div id="modal-template">
        <transition name="modal">
            <div class="modal-mask" @click="close">
                <div class="modal-wrapper">
                    <div class="modal-container" :class="type">
                        <div v-if="type == 'ticket'" class="modal_description">{{ $t('public.modal.remove_ticket', { ticket: ticketNumber }) }}</div>
                        <div v-if="type == 'resendBill'" class="modal_description">{{ $t('public.modal.resend_bill') }}</div>
                        <div v-if="type == 'regenerateBill'" class="modal_description">{{ $t('public.modal.regenerate_bill') }}</div>
                        <div v-if="type == 'termsAndConditions'" class="modal_description" v-html="data"></div>
                        <div v-if="type == 'cancelInvoice'" class="modal_description">{{ $t('public.modal.cancel_invoice', { invoiceNumber: invoiceNumber }) }}</div>
                        <div v-if="type == 'confirmResend'" class="modal_description">{{ $t('public.modal.confirm_resend_bill', { invoiceNumber, email }) }}</div>
                        <div class="actions">
                            <div v-if="['ticket', 'termsAndConditions', 'cancelInvoice', 'confirmResend'].includes(type)" class="action cancel" @click="close">{{ $t('public.modal.cancel') }}</div>
                            <div v-if="['ticket', 'termsAndConditions', 'cancelInvoice', 'confirmResend'].includes(type)" class="action confirm" @click="confirm">{{ $t('public.modal.confirm') }}</div>
                            <div v-if="['resendBill', 'regenerateBill'].includes(type)" class="action close" @click="close">{{ $t('public.modal.close') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        type: String,
        ticketNumber: String,
        data: String,
        invoiceNumber: String,
        email: String
    },
    methods: {
        close(event) {
            event.preventDefault()
            event.stopPropagation()
            this.$emit('close')
        },
        confirm(event) {
            event.preventDefault()
            event.stopPropagation()
            this.$emit('confirm')
        }
    }
}
</script>

<style lang="scss">
#modal-template {
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .modal-wrapper {
            position: relative;

            .modal-container {
                height: 520px;
                width: 410px;
                margin: 0px auto;
                background-color: white;
                border-radius: 4px;
                box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.15);
                background-position: top 20px center;
                background-repeat: no-repeat;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                justify-content: center;
                padding-bottom: 5px;

                &.number {
                    background-image: url('../../../public/img/tooltip_ticket.jpg');
                }

                &.date {
                    background-image: url('../../../public/img/tooltip_fecha.jpg');
                }

                &.price {
                    background-image: url('../../../public/img/tooltip_importe.jpg');
                }

                &.ticket {
                    height: 175px;
                    padding: 20px;
                }

                &.confirmResend,
                &.resendBill,
                &.regenerateBill,
                &.cancelInvoice {
                    height: 250px;
                    padding: 20px;
                }

                &.cancelInvoice {
                    text-align: center;
                }

                .modal_description {
                    font-family: Speedee;
                    font-size: 20px;
                }

                .actions {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-around;

                    .action {
                        border-radius: 4px;
                        font-size: 20px;
                        font-family: Speedee-bd;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        padding: 15px;
                        &.close,
                        &.confirm {
                            background-color: #e63946;
                            color: #fff;
                        }

                        &.cancel {
                            background-color: #ddd;
                        }
                    }
                }

                &.termsAndConditions {
                    width: 95%;
                    height: 100vh;
                    overflow-y: scroll;
                    padding: 40px 40px 20px 40px;
                    .actions {
                        .action {
                            &.confirm {
                                background-color: $green;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
